/* 
* Duff Framework
* ©Selltec Communications GmbH
*/
@import "definitions";

.CodeMirror {
  font-size: 1.3em;
  border: 1px solid #eee;
  height: auto;
}

.CodeMirror-scroll {
  min-height: 15em;
  max-height: 50em;
}

@each $id, $color in $cc-colors {
  .cc_#{$id} {
    background-color: $color !important;
  }

  div.entries_list table:not(.no_head):not(.add_in_list) > tbody:nth-child(2n + 1) > tr {
    &.cc_#{$id},
    & > td.cc_#{$id} {
      background-color: desaturate(darken($color, 5), 10) !important;
    }
  }
}

// wiki, drag&drop-bilder im editor

.wiki_draggable_img {
  display: inline-block;
  color: #444444;
  background-color: #ffffff;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  box-shadow: 1px 1px 2px #aaaaaa;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
  z-index: 1000;
  cursor: -webkit-grab;
}

.wiki_draggable_img.ui-draggable-dragging {
  cursor: -webkit-grabbing;
}

.wiki_img_trash {
  font-size: 370%;
  margin: 0.5em;
  background-color: #a8a5a1;
  color: #ffffff;
  padding: 0.5em 1em;
  border: 1px solid transparent;
  box-shadow: 1px 1px 2px #aaaaaa;
}

.wiki_form_field_input {
  vertical-align: top !important;
  padding-top: 1em !important;
}

div.wiki_draggable_img_thumb {
  display: table-cell;
  padding: 0.5em 0em 0.5em 0.5em;

  > img {
    width: 40px;
    height: 40px;
  }
}

div.wiki_draggable_img_filename {
  display: table-cell;
  vertical-align: middle;

  > span {
    vertical-align: middle;
    padding: 0.5em;
  }
}

a.wiki_user_tag {
  color: -webkit-link;
  text-decoration: none;
  cursor: auto;
  &:hover {
    text-decoration: underline;
  }
}

// Textmanager

div.textmanager {
  div.entries_list table {
    width: 100%;

    th.text_column {
      width: 49%;
    }

    td.text_column {
      white-space: normal;
      vertical-align: top;
    }
  }
}

// Copy Link Icon
.copy_link {
  @include sl-icon($fa-var-copy);
  cursor: pointer;
}

// Invoice Export
.invoice_export_list {
  display: inline-block;

  table {
    > thead > tr:last-child {
      border-bottom: 1px solid #ddd;
    }
    > tbody > tr:nth-child(even) {
      background-color: $theme_color_light;
    }
    th,
    td {
      padding: 0.5em 1em;
    }
  }

  .download_export {
    cursor: pointer;
    .download_icon {
      @include sl-icon($fa-var-download);
      padding-right: 0.2em;
    }
  }

  .description,
  .button.generate_export {
    margin-bottom: 1em;
  }
}

iframe.newsletter_preview {
  background-color: #fff;
  width: 100%;
  min-height: 300px;
}

// Statistics
.app_statistics {
  .interval_chooser {
    padding: $pad;
    border: $border;
    border-color: #ccc;
  }

  .graph_border {
    padding: 1em;
    border: $border;
    border-color: #ccc;
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  .interval_label {
    margin: 1em 1em 1em 0;
  }
  .stats_heading {
    text-align: center;
    > h1 {
      font-size: 2em;
      font-weight: bold;
    }
  }
}

.hidden_until_hover {
  display: none;
  position: absolute;
  z-index: 1000;

  padding: 0.5em 0.5em 1em 0.5em;
  background-color: #fff;
  border: 1px solid #ccc;

  &:hover {
    display: block;
  }
}

.show_next_on_hover:hover ~ * {
  display: block;
}

//Admin actions - quick email
.quick_email {
  .email_form {
    > div:not(:last-child) {
      margin-bottom: $mar;
    }

    .buttons {
      text-align: right;

      > a.button {
        margin-right: $mar;
      }
    }
  }
}

//Admin actions - direct answer (requests)
.direct_answer_action {
  .form_field {
    margin-bottom: 1em;
  }

  textarea {
    width: 100%;
    height: 6em;
  }

  .previous_replies {
    border: $border;

    table {
      width: 100%;

      tr {
        td,
        th {
          padding: 0.5em;
        }
        &:nth-child(even) {
          background-color: #eee;
        }
      }
    }
  }
}

//Order Reports
.order_reports form > div {
  display: inline-block;
  vertical-align: top;
  margin-right: $mar;

  i {
    margin-left: $mar_tiny;
    vertical-align: top;
  }
}

.date_picker_input {

  position: relative;
  display: flex;
  align-items: center;


  .is-date {
      min-width: 5em !important; //add better formatting to not need important
      max-width: 8em;
  }

  .is-time {
    margin-left: $mar !important; //add better formatting to not need important
    min-width: 3.8em !important; //add better formatting to not need important
    max-width: 3.8em;
  }

  >i.fa {
    padding-top:0;
  }

  select {
    min-width: 1px !important;
  }

  &-dialog {
    position: absolute;
    padding: 0.625rem;
    border: solid 1px #ddd;
    left: 0;
    top: 100%;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    z-index: 100;

    &_nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > span {
        display:flex;
        align-items: center;
      }

      .is-prev,
      .is-next {
        cursor: pointer;
        font-size: 1.5em;
      }
      .is-chooser {
        display: inline-flex;
        flex-direction: row;
        padding-left: $mar_small;
        padding-right: $mar_small;
      }
    }

    &_calendar {
      margin-top: 0.625rem;

      thead th {
        border: solid 1px #ddd;
        padding: 0.3125rem 0.625rem;
      }
      tbody td {
        cursor: pointer;
        border: solid 1px #ddd;
        padding: 0.3125rem 0.625rem;
        text-align: center;
      }
      tbody td:hover {
        background-color: #ddd;
      }
      tbody td.is-today {
        background-color: #d0d0d0;
      }
      tbody td.is-selected {
        background-color: #e2f3eb;
      }
      tbody td.is-not-current:not(:hover) {
        opacity: 0.5;
      }
    }
  }
}
